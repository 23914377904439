<template>
  <div v-if="data.data">
    <hot-table
      ref="hottable"
      licenseKey='non-commercial-and-evaluation'
      :data="dataPrintable"
      :settings="settings"
      :columnSorting="columnSorting"
      :rowHeaders="rowHeaders"
      :colHeaders="colHeaders"
      :columns="columns"
      :readOnly="readOnly"
      :height="height"
      :width="'100%'"
      stretchH="all"
    ></hot-table>
  </div>
</template>

<script>
import { HotTable } from '@handsontable/vue'

export default {
  name: 'DataView',
  components: {
    'hot-table': HotTable
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: 350
    },
    showClassInfo: {
      type: Boolean,
      default: false
    },
    showProgressInfo: {
      type: Boolean,
      default: false
    },
    sort: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      settings: {
      }
    }
  },
  computed: {
    dataPrintable: function () {
      let toReturn = []
      if (this.data && this.data.data) {
        this.data.data.forEach(element => {
          let copy = Object.assign({}, element)
          delete copy._row
          toReturn.push(copy)
        })
      }
      return toReturn
    },
    rowHeaders: function () {
      if (this.data) {
        return this.data.data.map(obj => obj._row)
      }
      return []
    },
    colHeaders: function () {
      return this.data.fields.map(obj => this.colHeadersRender(obj))
    },
    columns: function () {
      let fields = []
      this.data.fields.forEach(element => {
        if (element.class === 'numeric') {
          fields.push({
            data: element.id,
            type: element.class })
        } else {
          fields.push({ data: element.id })
        }
      })
      return fields
    },
    columnSorting: function () {
      if (this.sort) {
        return {
          sortEmptyCells: true, // true = the table sorts empty cells, false = the table moves all empty cells to the end of the table
          indicator: true, // true = shows indicator for all columns, false = don't show indicator for columns
          headerAction: true
        }
      } else {
        return false
      }
    }
  },
  watch: {
    data: function () {
      this.$refs.hottable.hotInstance.loadData(this.dataPrintable)
    }
  },
  methods: {
    columnsCount: function () {
      let count = 0
      if ('fields' in this.data) {
        count = this.data.fields.length
      }
      return count
    },
    colHeadersRender: function (field) {
      let spans = []
      spans.push(field.label)
      if (this.showClassInfo) {
        spans.push('<p class="' + field.class + '">' + field.class + '</p> ')
      }
      if (this.showProgressInfo) {
        spans.push('<div class="progress-wrapper">' +
        '<progress class="progress is-small is-danger" value=' + field.propmissing * 100 + ' max="100"></progress>' +
        '<p class="progress-value">' + (field.propmissing * 100).toFixed(2) + '%</p></div>')
      }
      return spans.join('')
    }
  }
}
</script>

<style src="../../../node_modules/handsontable/dist/handsontable.full.css"></style>
<style>
.handsontable th {
  text-align: center !important;
  font-size: 15px;
}
.ht_clone_top {
  z-index: 0;
}
.ht_clone_left
{
  z-index: 0;
}
.ht_clone_top_left_corner {
  z-index: 0;
}
.progress {
  width: 120px;
  margin-left: auto;
  margin-right: auto;
}
.categorical {
  color: #5aa078;
  font-size: 11px;
}
.logical {
  color: #d24c2f;
  font-size: 11px;
}
.numeric {
  color: #3498db;
  font-size: 11px;
}
.other {
  color: #af76c6;
  font-size: 11px;
}
</style>
