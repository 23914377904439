<template>
  <b-steps
    v-model="activeStep"
    :animated="true"
    :rounded="true"
    :has-navigation="false"
  >
    <b-step-item step="1" label="File upload">
      <br />
      <br />
      <div class="columns">
        <div class="column is-6">
          <card-component title="1. Select a file to upload" class="tile is-child">
            <validation-observer v-slot="{ handleSubmit }">
              <validation-provider
                v-slot="{ errors, valid }"
                :rules= {required:true,size_limit:limit,is_text_file:true}
                :customMessages = "{size_limit: 'Size file is limited to '+getReadableFileSizeString+' , please use docker on your desktop for large file',
                is_text_file: 'Format not supported'
                }"
                name="dataset name"
              ><b-field
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                  label="File"
                >
                    <b-upload expanded v-model="dropFile">
                      <a class="button is-primary is-fullwidth">
                        <b-icon icon="upload"></b-icon>
                        <span>{{ dropFile.name || "Select a file"}}</span>
                      </a>
                    </b-upload>
                  </b-field>
                </validation-provider>
              <validation-provider
                v-slot="{ errors, valid }"
                :rules= {required:true,excluded:datasetsNames,does_not_contains:true,authorize_name:true,not_too_long:true}
                :customMessages = "{excluded: 'This dataset name has already been used. Choose another one.',
                  does_not_contains: 'The dataset name should not contain special symbols (except underscore) nor start with numbers.',
                  authorize_name: 'This dataset name is not authorized',
                  not_too_long: 'This dataset name is too long'}"

                name="dataset name"
              >
                <b-field
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                  label="Dataset name"
                >
                  <b-input
                    v-model="dataset.name"
                    expanded
                    icon="tag"
                    placeholder="Dataset name"
                  ></b-input>
                  </b-field>
                </validation-provider>

              <br />
              <validation-provider
                v-slot="{ errors, valid }"
                rules="required"
                name="dataset nature"
              >
                <b-field
                  label="Dataset nature"
                  :message="errors"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <b-select
                    v-model="dataset.nature"
                    placeholder="Dataset nature"
                    class="mb-2"
                    expanded
                    icon="asterisk"
                  >
                    <option
                      v-for="(cnature,index) in datasetNatures"
                      :key="index"
                      :value="cnature.key"
                    >
                      {{ cnature.nature }}
                    </option>
                  </b-select>
                </b-field>
                <font size="2"><p style="color:red;">If you don't know which nature to choose, use “generic”.</p><br></font>
                <div class="columns">
                  <div class="column is-three-quarters">
                    <b-field
                      label="Before importation"
                    >
                      <b-switch
                        v-model="log"
                      >
                        Have data been log-transformed?
                      </b-switch>
                    </b-field>
                    <b-field
                      label=""
                    >
                      <b-switch
                        v-model="normalized"
                      >
                        Have data been normalized?
                      </b-switch>
                    </b-field>
                  </div>
                  <div style="position: absolute; right: 30%;">
                    <div class="column">
                      <a hidden ref="export" href=""></a>
                      <bt-help class="help-export" wfclass="ImportAttributes"></bt-help>
                    </div>
                  </div>
                </div>
              </validation-provider>
              <hr />
              <nav class="step-navigation is-pulled-right">
                <b-button
                  rounded
                  class="button pagination-previous"
                  disabled
                >
                  <span class="icon is-small"><i class="fa fa-chevron-left"></i></span>
                </b-button>
                <b-button
                  rounded
                  class="button pagination-next is-primary"
                  @click="handleSubmit(goNextStep)"
                >
                  <span class="icon is-small"><i class="fa fa-chevron-right"></i></span>
                </b-button>
              </nav>
              <br/>
              <br/>
            </validation-observer>
          </card-component>
        </div>
        <div class="column is-6">
          <card-component title="Raw file" icon="search" class="tile is-child">
            <pre style="height: 265px; overflow-x: auto !important;">{{ rawData }}</pre>
          </card-component>
        </div>
      </div>
    </b-step-item>
    <b-step-item step="2" label="Dataset structure">
      <br />
      <div class="columns">
        <div class="column is-6">
          <card-component title="2. Set the dataset structure" class="tile is-child" :displayHelp="$options.name">
            <validation-observer v-slot="{ handleSubmit }">
              <b-field
                label=""
              >
                <b-switch
                  v-model="header"
                  @input="getRPreview"
                  >
                  Column names are included in the first row
                </b-switch>
              </b-field>
              <div class="columns is-vcentered">
                <div class="column is-4">
                  <b-switch
                    v-model="rowLabels"
                    @input="getRPreview"
                    >
                    File has row labels
                  </b-switch>
                </div>
                <div class="column">
                  <validation-provider
                    v-slot="{ errors, valid}"
                    :rules= {required:rowLabels,integer:true,min_value:1,max_value:columnsCount}
                    name="row names"
                  >
                  <b-field
                    label="Index of the column with row labels"
                    :type="{ 'is-danger': rowNamesError(errors[0]), 'is-success': rowNamesValid(valid) }"
                    :message="rowNamesMessage(errors)"
                  >
                    <b-input
                      :disabled="!rowLabels"
                      v-model="rownames"
                      expanded
                      @input="getRPreview"
                    ></b-input>
                    </b-field>
                  </validation-provider>
                </div>
              </div>
              <div class="columns is-vcentered">
                <div class="column is-4">
                  <b-switch
                    v-model="hasCommentLine"
                    @input="getRPreview"
                    >
                    File has comment lines
                  </b-switch>
                </div>
                <div class="column">
                  <b-field
                    label="Comment character"
                  >
                    <b-input
                      :disabled="!hasCommentLine"
                      v-model="comment"
                      expanded
                      :maxlength=1
                      @input="getRPreview"
                    ></b-input>
                    </b-field>
                </div>
              </div>
              <b-field
                label=""
              >
                <b-switch
                  v-model="transpose"
                  @input="getRPreview"
                  >
                  Switch rows and columns (transpose)<br>
                  <font size="2"><p style="color:red;">For ASTERICS, individuals (biological samples) must be in rows.</p></font>
                </b-switch>
              </b-field>
              <hr />
              <b-field
                label="Separator"
              >
                <div class="block is-flex is-align-items-center">
                  <b-radio
                    v-model="separator"
                    native-value=","
                    @input="customSeparatorChanged"
                  >
                    , (comma)
                  </b-radio>
                  <b-radio
                    v-model="separator"
                    native-value=";"
                    @input="customSeparatorChanged"
                  >
                    ; (semicolon)
                  </b-radio>
                  <b-radio
                    v-model="separator"
                    native-value="\t"
                    @input="customSeparatorChanged"
                  >
                    \t (tab)
                  </b-radio>
                  <b-radio
                    v-model="separator"
                    native-value=" "
                    @input="customSeparatorChanged"
                  >
                    Space
                  </b-radio>
                  <b-radio
                    v-model="separator"
                    native-value="*"
                    @input="customSeparatorChanged"
                  >
                    Custom :
                  </b-radio>
                  <b-field>
                    <b-input
                      class="is-flex is-align-items-center"
                      style="width:40px;"
                      v-model="customSeparator"
                      @input="getRPreview"
                      @focus="customSeparatorFocused"
                      maxlength="1"
                      :has-counter="false"
                    />
                  </b-field>
                </div>
              </b-field>
              <hr />
              <b-field
                label="Quote mark for text"
              >
                <b-select
                  v-model="quote"
                  expanded
                  @input="getRPreview"
                >
                  <option value='"'>" (double quote)</option>
                  <option value="'">' (simple quote)</option>
                  <option value="">Nothing</option>
                </b-select>
              </b-field>
              <b-field
                label="Decimal mark"
                >
                <div class="block">
                  <b-radio
                    v-model="dec"
                    native-value="."
                    @input="getRPreview"
                  >
                    . (dot)
                  </b-radio>
                  <b-radio
                    v-model="dec"
                    native-value=","
                    @input="getRPreview"
                  >
                    , (comma)
                  </b-radio>
                </div>
              </b-field>
              <b-field
                label="Encoding"
              >
                <div class="block">
                  <b-radio
                    v-model="encoding"
                    native-value="unknown"
                    @input="getRPreview"
                  >
                    Auto
                  </b-radio>
                  <b-radio
                    v-model="encoding"
                    native-value="UTF-8"
                    @input="getRPreview"
                  >
                    UTF-8
                  </b-radio>
                  <b-radio
                    v-model="encoding"
                    native-value="latin1"
                    @input="getRPreview"
                  >
                    latin1
                  </b-radio>
                </div>
              </b-field>
              <b-field
                label="Missing values are identified with these strings in the uploaded file:"
              >
                <b-taginput
                  v-model="nastrings"
                  :data="valuesNastrings"
                  :allow-new="false"
                  :open-on-focus="true"
                  ellipsis
                  icon="caret-right"
                  placeholder="Add na.strings">
                </b-taginput>
              </b-field>
              <hr />
              <font size="4"><p style="color:red;">Be patient: large files might take a long time to be uploaded and processed!</p><br></font>
              <nav class="step-navigation is-pulled-right">
                <b-button rounded
                  class="button pagination-previous"
                  @click="goPreviousStep()"
                >
                  <span class="icon is-small"><i class="fa fa-chevron-left"></i></span>
                </b-button>
                <b-button rounded
                class="button is-primary pagination-next"
                @click="handleSubmit(addDataset)"
              >
                <span class="icon is-small"><i class="fa fa-upload"></i></span>
                <span>Import</span>
              </b-button>
              </nav>
              <br/>
              <br/>
            </validation-observer>
          </card-component>
        </div>
        <div class="column is-6">
          <card-component title="Raw file" icon="search" class="tile is-child">
            <pre style="height: 265px; overflow-x: auto !important;">{{ rawData }}</pre>
          </card-component>
          <br />
          <card-component title="Dataset preview" icon="search" class="tile is-child">
            <div v-if="activeStep!==0">
              <error :type='"notifications"' />
              <b-skeleton
                v-if="previewLoading"
                height="360"
              ></b-skeleton>
              <data-view
                v-else-if='rData'
                ref="dataview"
                :data="rData"
                :showClassInfo="true"
                :height="345"
              >
              </data-view>
            </div>
          </card-component>
        </div>
      </div>
    </b-step-item>
  </b-steps>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import apiService from '@/services/apiService'
import DataView from '@/components/rresults/DataView.vue'
import natureDictionary from '../../data/natureDictionary.json'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useErrorStore } from '@/stores/error'

const NB_LINE_PREVIEW = 21
const RAW_DATA_DEFAULT = '\n\n\n\n                      Select a file to get a preview'
const VALUES_NASTRINGS = ['NA', 'NR', 'missing']

export default {
  name: 'ImportDataset',
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'data-view': DataView,
    'error': () => import('@/components/ui/Error.vue'),
    'bt-help': () => import('@/components/help/secondlevel/BtHelp.vue')
  },
  data () {
    return {
      activeStep: 0,
      datasetNatures: natureDictionary.filter(el => el.from === 'user'),
      valuesNastrings: VALUES_NASTRINGS,
      error: '',
      columnsCount: 0,
      previewLoading: false,
      rawData: RAW_DATA_DEFAULT,
      rData: {},
      dropFile: {},
      dataset: {
        id: null,
        name: '',
        nature: null,
        status: 'dataset',
        parent: []
      },
      header: true,
      rowLabels: true,
      separator: ',',
      customSeparator: '',
      quote: '"',
      dec: '.',
      rownames: 1,
      transpose: false,
      encoding: 'unknown',
      nastrings: ['NA'],
      log: false,
      normalized: false,
      comment: '#',
      hasCommentLine: false,
      limit: 0,
      serverFilename: ''
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const errorStore = useErrorStore()
    return { workspaceStore, errorStore }
  },
  mounted () {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })
    this.workspaceStore.getWorkflow().then(() => {
      loadingComponent.close()
    })
      .catch(() => {
        loadingComponent.close()
      })
    extend('does_not_contains', {
      getMessage: field => 'The ' + field + ' value is not truthy.',
      validate: value => value.match(/^[a-zA-Z0-9_]*$/) && !value.match(/^\d/)
    })
    extend('not_too_long', {
      getMessage: field => 'The ' + field + ' value is not truthy.',
      validate: value => value.length < 40
    })
    extend('size_limit', {
      getMessage: field => 'The ' + field + ' value is not truthy.',
      validate:
       function (value, [limit]) {
         return limit === 0 || value.size < limit
       }
    })
    extend('is_text_file', {
      validate:
       function (value) {
         if (value && value.type) {
           if (value.type === 'text/html') {
             return false
           }
           return value.type.startsWith('text/')
         }
         return true
       }
    })
    extend('authorize_name', (value) => {
      return apiService.runRFunction({
        'func_name': 'r_forbidden_name',
        'username': value
      }).then((res) => {
        return {
          valid: !res.forbidden
        }
      })
    })
    this.workspaceStore.getLimitFileSize().then((data) => {
      this.limit = data
    })
      .catch(() => {
        this.limit = 0
      })
  },
  computed: {
    getReadableFileSizeString: function () {
      var i = -1
      var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB']
      let fileSizeInBytes = this.limit
      do {
        fileSizeInBytes = fileSizeInBytes / 1024
        i++
      } while (fileSizeInBytes > 1024)
      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i]
    },
    datasetsNames: function () {
      let datasets = this.workspaceStore.datasets
      return datasets.map(dataset => dataset.user_name)
    },
    hasError: function () {
      return this.errorStore.hasError
    },
    isFileOk: function () {
      if (this.dropFile && this.dropFile.type) {
        if (this.dropFile.type === 'text/html') {
          return false
        }
        return this.dropFile.type.startsWith('text/')
      }
      return true
    }
  },
  watch: {
    dropFile: function () {
      if ('name' in this.dropFile) {
        const reader = new FileReader()
        const self = this
        this.dataset.name = this.dropFile.name.replace(/\.[^/.]+$/, '').replace(/[-]/g, '')
        this.rawData = ''
        reader.addEventListener('load', function (event) {
          const lines = event.target.result.split('\n')
          for (let i = 0; i < lines.length; i++) {
            if (lines[i]) {
              if (i < NB_LINE_PREVIEW) {
                self.rawData += lines[i] + '\n'
              }
            }
          }
        })
        reader.readAsText(this.dropFile)
      }
    }
  },
  methods: {
    rowNamesValid: function (valid) {
      let rowNamesValid = valid
      if (!this.rowLabels) {
        rowNamesValid = false
      }
      return rowNamesValid
    },
    rowNamesError: function (error) {
      let rowNamesError = error
      if (!this.rowLabels) {
        rowNamesError = false
      }
      return rowNamesError
    },
    rowNamesMessage: function (msg) {
      let rowNamesMessage = msg
      if (!this.rowLabels) {
        rowNamesMessage = ''
      }
      return rowNamesMessage
    },
    customSeparatorChanged: function () {
      this.customSeparator = ''
      this.getRPreview()
    },
    customSeparatorFocused: function () {
      this.separator = '*'
      this.getRPreview()
    },
    goNextStep: function () {
      if (this.error === '' && this.isFileOk) {
        this.saveFile()
        this.activeStep += 1
      }
    },
    goPreviousStep: function () {
      this.activeStep -= 1
      this.error = ''
    },
    saveFile: function () {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      apiService.saveFile({
        file: this.dropFile })
        .then((data) => {
          loadingComponent.close()
          if (data.Messages) {
            this.errorStore.setNotifications(data.Messages.data)
          }
          if (data.filename && data.filename !== '') {
            this.serverFilename = data.filename
            this.getRPreview()
          }
        })
        .catch(() => {
          loadingComponent.close()
        }
        )
    },
    resetForm: function () {
      this.dataset = {
        id: null,
        name: '',
        nature: null,
        status: 'dataset',
        parent: []
      }
      this.dropFile = {}
      this.rawData = RAW_DATA_DEFAULT
      this.rData = {}
      this.columnsCount = 0
      this.header = true
      this.separator = ','
      this.quote = '"'
      this.dec = '.'
      this.rownames = 1
      this.transpose = false
      this.encoding = 'unknown'
      this.nastrings = ['NA']
      this.serverFilename = ''
    },
    getRPreview: function () {
      if (this.rawData !== '') {
        this.previewLoading = true
        this.rData = {}
        // remove previous error
        let params = {
          'func_name': 'r_import',
          'input': this.serverFilename,
          'header': this.header,
          'sep': this.separator === '*' ? this.customSeparator : this.separator,
          'quote': this.quote,
          'dec': this.dec,
          'encoding': this.encoding,
          'na.strings': this.nastrings,
          'transpose': this.transpose,
          'preview': true,
          'out_graph': true
        }
        if (this.rowLabels) {
          params['row.names'] = parseInt(this.rownames)
        }
        if (this.hasCommentLine && this.comment !== '') {
          params['comment'] = this.comment
        }
        apiService.runRFunction(params)
          .then(data => {
            this.previewLoading = false
            this.rData = data.DataView
            this.$nextTick(() => {
              if (this.$refs.dataview) {
                this.columnsCount = this.$refs.dataview.columnsCount()
              }
            })
          })
          .catch(() => {
            this.previewLoading = false
          })
      }
    },
    addDataset: function () {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let params = {
        'nature': this.dataset.nature,
        'header': this.header,
        'sep': this.separator,
        'quote': this.quote,
        'dec': this.dec,
        'transpose': this.transpose,
        'encoding': this.encoding,
        'na.strings': this.nastrings,
        'log': this.log ? 'yes' : 'no',
        'normalized': this.normalized ? 'yes' : 'no',
        'out_graph': true
      }
      if (this.rowLabels) {
        params['row.names'] = parseInt(this.rownames)
      }
      if (this.hasCommentLine && this.comment !== '') {
        params['comment'] = this.comment
      }
      this.workspaceStore.addDataset({
        file: this.serverFilename,
        dataset: this.dataset,
        parameters: params
      })
        .then((data) => {
          loadingComponent.close()
          if (data.Messages) {
            this.errorStore.setNotifications(data.Messages.data)
          } else {
            this.resetForm()
            this.$router.push({ name: 'workspace' })
          }
        })
        .catch(() => {
          loadingComponent.close()
        }
        )
    }
  }
}
</script>
<style>
ul {
  list-style: none !important;
}
</style>
<style scoped>
.img-help {
  margin-top: 5px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
  }
</style>
